import { useState, useEffect } from 'react'
import { Page, Card, Display, Button, Grid, Spacer, Text, Link, Loading, Description, Note, Fieldset, Input, Checkbox, Radio, User } from '@geist-ui/react'
import { Play, Heart, Bookmark, UserPlus } from '@geist-ui/react-icons'
import Header from './components/Header'
import Footer from './components/Footer'
import React from 'react'
import Dropzone from 'react-dropzone'



export default function Submit({session}) {

    async function postFile(files) {
        // test data
        console.log(files);
        let headers = new Headers();
        headers.append("X-Api-Key", "A05PGDFXNA6BD82MFG05C9J1");
        headers.append("Content-Type", "application/json");
        //options parameter for fetch
        const options = {
            method: 'POST',
            headers: headers,
            body: files
          }
        // call API
        fetch('https://api.artistsmatter.xyz/tracks/upload',options)
        .then((data)=> {console.log(data)})
        
    }

    return (
        <Page width="800px" padding={0}>
            <Header />
            <Spacer h={4} />
            <Grid.Container gap={1}>
                <Grid xs={12}>
                    <Grid.Container gap={3}>
                        <Grid xs={24}>
                            <Radio.Group value={['track']} useRow>
                                <Radio value="track">Track</Radio>
                                <Radio value="album">Album</Radio>
                            </Radio.Group>
                        </Grid>
                        <Grid xs={24}>
                            <Input htmlType='text' width='100%'>Title</Input>
                        </Grid>
                        <Grid xs={24}>
                        <Checkbox.Group value={['rock']}>
                                <Checkbox value="rock">Rock</Checkbox>
                                <Checkbox value="pop">Pop</Checkbox>
                                <Checkbox value="electronic">Electronic</Checkbox>
                            </Checkbox.Group>
                        </Grid>
                        <Grid xs={24}>
                            <User src="https://avatars.dicebear.com/api/bottts/seed.svg" name="Russian Famous Nobody"/>
                            <Button icon={<UserPlus />} ghost auto>Add</Button>
                        </Grid>
                    </Grid.Container>
                </Grid>
                <Grid xs={12}>
                    <Grid.Container gap={3}>
                        <Grid xs={24}>
                            <Input htmlType='text' width='100%'>Description</Input>
                        </Grid>
                        <Grid xs={24}>
                        <Dropzone onDrop={acceptedFiles => postFile(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Text blockquote>Drag 'n' drop some files here, or click to select files</Text>
                                </div>
                                </section>
                            )}
                            </Dropzone>
                        </Grid>
                    </Grid.Container>
                </Grid>
                <Grid xs={24}>
                    <Button>Upload</Button>
                </Grid>
            </Grid.Container>
            
            <Footer />
        </Page>
    )
}