import { useState, useEffect } from 'react'
//import { supabase } from './supabaseClient'
import { Page, Card, Display, Button, Grid, Spacer, Text, Link, Loading, Description, Note, Fieldset } from '@geist-ui/react'

import Header from './components/Header'
import Footer from './components/Footer'

export default function Welcome({ session }) {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(null)
  const [website, setWebsite] = useState(null)
  const [avatar_url, setAvatarUrl] = useState(null)

  const data = {
    mnemonic: "bla bla"

  }

  useEffect(() => {
    getProfile()
  }, [session])

  async function getProfile() {
    try {
      setLoading(true)

      let headers = new Headers();
      headers.append("X-Api-Key", "A05PGDFXNA6BD82MFG05C9J1");
      headers.append("Content-Type", "application/json");
/*
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          id: user.id})
      }
      */
      //fetch('https://api.artistsmatter.xyz/users/create',options)
      //.then((data) => console.log(data))
    


    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function updateProfile({ username, website, avatar_url }) {
    try {
      setLoading(true)
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (


    <Page width="800px" padding={0}>
    <Header />
    <Spacer h={4} />
    <Fieldset>
      <Fieldset.Content>
      <Fieldset.Title>Welcome! We are creating an account for you</Fieldset.Title>
      <Loading hidden={loading} />
      <Note>This information is private, save and don't share with anybody</Note>
      <Spacer h={2} />
      <Description title="Mnemonic" content={data.mnemonic} />
      </Fieldset.Content>
      <Fieldset.Footer>
        <Button auto>Next</Button>
      </Fieldset.Footer>
    </Fieldset>
    <Footer />
  </Page>

    
  )
}




        /*
    <div className="form-widget">
      <Header session={!session ? {session} : false}/>
      <div>
        <label htmlFor="email">Email</label>
        <input id="email" type="text" value={session.user.email} disabled />
      </div>
      <div>
        <label htmlFor="username">Name</label>
        <input
          id="username"
          type="text"
          value={username || ''}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="website">Website</label>
        <input
          id="website"
          type="website"
          value={website || ''}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>

      <div>
        <button
          className="button block primary"
          onClick={() => updateProfile({ username, website, avatar_url })}
          disabled={loading}
        >
          {loading ? 'Loading ...' : 'Update'}
        </button>
      </div>

      <div>
        <button className="button block" onClick={() => supabase.auth.signOut()}>
          Sign Out
        </button>
      </div>
    </div>



        */