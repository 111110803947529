// React core
import { useState, useEffect } from 'react'
// Geist UI
import { Page, Modal, Button, Grid, Table, Input, Checkbox, User, Image, Text, Tabs, Card, useTabs, Select } from '@geist-ui/react'
import { UserPlus } from '@geist-ui/react-icons'
// 3rd party components
import Dropzone from 'react-dropzone'
// Custom components
import Header from './components/Header'
import Footer from './components/Footer'
// Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, onSnapshot, updateDoc, addDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
//Web3
import { ethers } from "ethers";
import Web3Modal from "web3modal";

// mock data
import Artistsmatter from "./artifacts/contracts/Artistsmatter.sol/Artistsmatter.json";

const contractAddress = "0xBCC3e202b43abA9165b64b814dBfc18Fb37659fa";

export default function PlayComponent() {
    // Init Firebase 
    const db = getFirestore(); // Firestore for docs
    const auth = getAuth(); // Firebase auth
    const storage = getStorage(); // Firebase storage for media

    // Page state management
    const [loading, setLoading] = useState(true)

    // Modal state management
    const [modalState, setModalState] = useState(false)
    const modalHandler = () => setModalState(true)
    const closeModalHandler = (event) => {
        setModalState(false)
    }
    const [modalFilesState, setModalFilesState] = useState([])


    // Tabs state management
    const { setState, bindings } = useTabs('1')


    // Function to upload files at dropzone
    async function postFile(files) {

        console.log(files);

        const modalFiles = modalFilesState;
        modalFiles.push(files[0])
        setModalFilesState(modalFiles)


    }

    async function mintTrack(trackurl) {
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();

        let contract = new ethers.Contract(contractAddress,Artistsmatter.abi,signer);
        let listingPrice = await contract.getListingPrice()
        listingPrice = listingPrice.toString()
    
        const auctionPrice = ethers.utils.parseUnits('1', 'ether')
        let transaction = await contract.createToken("https://www.mytokenlocation.com",auctionPrice, { value: listingPrice });
        await transaction.wait();
        console.log(transaction);
        /*
        let headers = new Headers();
        headers.append("X-Api-Key", "A05PGDFXNA6BD82MFG05C9J1");
        headers.append("Content-Type", "application/json");
        const options = {
            method: 'POST',
            headers: headers,
            body: file
        }
        fetch('https://api.artistsmatter.xyz/tracks/upload', options)
            .then((data) => { console.log(data) })
        */

    }

    async function uploadMedia() {
        console.log('Uploading media file...');
        // Create new document in Tracks collection
        const docRef = await addDoc(collection(db, "Tracks"), {
            Cover: "",
            Description: "",
            Genre: "",
            Media: "",
            Name: "",
            uuid: auth.currentUser.uid
        });

        //Create ref for that new document
        const storageRef = ref(storage, auth.currentUser.uid + '/' + docRef.id);
        // Upload media to that ref
        uploadBytes(storageRef, modalFilesState[0]).then((snapshot) => {
            // get download URL for the media
            getDownloadURL(snapshot.ref).then(async (url) => {
                console.log(url);
                // update doc with download link
                await updateDoc(docRef,
                    {
                        Media: url
                    })
                mintTrack(url)
            })
        });
        //Update with a link






    }
    // Tracklist state management
    const [tracklist, setTrackist] = useState([]);
    useEffect( async () => {
        const q = query(collection(db, "Tracks"), where("uuid", "==", auth.currentUser.uid));
        const querySnapshot =  await getDocs(q);
        const tracks = [];
        querySnapshot.forEach((doc) => {
                tracks.push(doc.data());
            });
            tracks.forEach((track) => track.Img = <Image width='100px' src={track.Cover}></Image>)
            setTrackist(tracks)
    },[modalState]);

    return (
        <Page width="800px" padding={0}>
            <Modal visible={modalState} onClose={closeModalHandler} width="45rem">
                <Modal.Title>Add new track</Modal.Title>

                <Modal.Content>
                    <Tabs {...bindings} align="space-between">
                        <Tabs.Item label="1 Input metadata" value="1">
                            <Grid.Container gap={3}>
                                <Grid xs={24}>
                                    <Input htmlType='text' width='100%'>Title</Input>
                                </Grid>
                                <Grid xs={24}>
                                    <Checkbox.Group value={['electronic']}>
                                        <Checkbox value="rock">Rock</Checkbox>
                                        <Checkbox value="pop">Pop</Checkbox>
                                        <Checkbox value="electronic">Electronic</Checkbox>
                                    </Checkbox.Group>
                                </Grid>
                                <Grid xs={24}>
                                    <Input htmlType='text' width='100%'>Description</Input>
                                </Grid>
                            </Grid.Container>
                        </Tabs.Item>
                        <Tabs.Item label="2 Upload Media" value="2">
                            <Grid.Container gap={3}>
                                <Grid xs={24} gap={3}>
                                    <Card width="50%">
                                        <Text h4>Track</Text>
                                        <Card.Footer>
                                            <Button>Upload</Button>
                                        </Card.Footer>
                                    </Card>
                                    <Card width="50%">
                                        <Text h4>Cover</Text>
                                        <Card.Footer>
                                            <Button>Upload</Button>
                                        </Card.Footer>
                                    </Card>
                                </Grid>
                            </Grid.Container>
                        </Tabs.Item>
                        <Tabs.Item label="3 Add authors" value="3">
                            <Grid.Container gap={3}>
                            <Grid xs={24} justify='space-between'>
                                    <User src="https://avatars.dicebear.com/api/bottts/seed.svg" name="Russian Famous Nobody" />
                                    <Button icon={<UserPlus />} ghost auto>Add</Button>
                                </Grid>
                            </Grid.Container>
                        </Tabs.Item>
                        <Tabs.Item label="4 Create NFT" value="4">
                            <Grid.Container gap={3}>
                                <Grid xs={24}>
                                    <Text blockquote>
                                        We will now mint an NFT for you. You can either sign it with you personal wallet (e.g. MetaMask) or use our wallet. Please note that in first case you will pay for the gas.
                                    </Text>
                                </Grid>
                                <Grid xs={24}>
                                    <Select placeholder="Choose wallet" width="100%">
                                        <Select.Option value="amwallet">Artistsmatter Wallet</Select.Option>
                                        <Select.Option value="metamask">MetaMask</Select.Option>
                                    </Select>
                                </Grid>
                            </Grid.Container>
                        </Tabs.Item>
                    </Tabs>
                </Modal.Content>
                <Modal.Action passive onClick={() => setModalState(false)}>Cancel</Modal.Action>
                
                <Modal.Action onClick={uploadMedia}>Publish</Modal.Action>
            </Modal>

            <Header />
            <Grid.Container justify="center" gap={2}>
                <Grid xs={24}>
                    <Button onClick={modalHandler}>Add new track</Button>
                </Grid>
                <Grid xs={24}>
                    <Table data={tracklist}>
                        <Table.Column prop="Img" label="Cover" />
                        <Table.Column prop="Description" label="Description" />
                        <Table.Column prop="Genre" label="Genre" />
                        <Table.Column prop="Name" label="Name" />
                    </Table>
                </Grid>
            </Grid.Container>


            <Footer />
        </Page>


    )
}