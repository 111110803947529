import { useState, useEffect } from 'react'
import { Page, Card, Grid, Spacer, Link, Loading } from '@geist-ui/react'

import { getAuth, onAuthStateChanged } from "firebase/auth";

import Header from './components/Header'
import Footer from './components/Footer'

export default function Account() {
  const [loading, setLoading] = useState(true)
  
  
  useEffect(() => {
    setLoading(true)
    const auth = getAuth();
    console.log(auth);
    onAuthStateChanged(auth, (user) => {
      if (user) {

        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log(user)

        setLoading(false)
        //const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    })  }, [])

  return (


    <Page width="800px" padding={0}>
    <Header />
    <Spacer h={4} />
    <Grid.Container gap={1.5}>
  <Grid xs={24} justify="center">
    <Card width='100%'>
      <h4>Create account</h4>
      <Loading hidden={loading} />
      <p>Modern and minimalist React UI library.</p>
      <Card.Footer>
        <Link target="_blank" href="https://github.com/geist-org/react">Next</Link>
      </Card.Footer>
    </Card>
  </Grid>
</Grid.Container>
    <Footer />
  </Page>

    
  )
}

/*

        <Grid.Container justify="center" gap={3} mt="100px">
          <Grid xs={20} sm={7} justify="center" gap={2}>
            {tracklist.map((track)=>(
              <Card width={300} key={track.id}>
                <Text h4 mb={0}>{track.track}</Text>
                <Text type="secondary">{track.artist}</Text>
                <Card.Footer>
                  <Button iconRight={<Heart/>} auto />
                  <Spacer w={.5} inline />
                  <Button iconRight={<Play/>} auto />
                  <Spacer w={.5} inline />
                  <Button iconRight={<Bookmark/>} auto />
                </Card.Footer>
              </Card>
            ))}
            
          </Grid>
  
        </Grid.Container>


        */



        /*
    <div className="form-widget">
      <Header session={!session ? {session} : false}/>
      <div>
        <label htmlFor="email">Email</label>
        <input id="email" type="text" value={session.user.email} disabled />
      </div>
      <div>
        <label htmlFor="username">Name</label>
        <input
          id="username"
          type="text"
          value={username || ''}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="website">Website</label>
        <input
          id="website"
          type="website"
          value={website || ''}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>

      <div>
        <button
          className="button block primary"
          onClick={() => updateProfile({ username, website, avatar_url })}
          disabled={loading}
        >
          {loading ? 'Loading ...' : 'Update'}
        </button>
      </div>

      <div>
        <button className="button block" onClick={() => supabase.auth.signOut()}>
          Sign Out
        </button>
      </div>
    </div>



        */