
import { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";

//import Auth from './Auth'
import Account from './Account'
import Public from './Public'
import Welcome from './Welcome'
import Play from "./Play"
import Submit from "./Submit"
import "./App.css"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIR0C3PfLuiynrm7289MOpo_tLMBFGUF8",
  authDomain: "artistsmatterinc.firebaseapp.com",
  projectId: "artistsmatterinc",
  storageBucket: "artistsmatterinc.appspot.com",
  messagingSenderId: "355384649992",
  appId: "1:355384649992:web:c5d0ab1823c3eca013a4bd",
  measurementId: "G-FJJPMR92SP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log(app)
const analytics = getAnalytics(app);


const App = () => {

  const [session, setSession] = useState(false)

  useEffect(() => {
    const auth = getAuth();
    console.log(auth);
    onAuthStateChanged(auth, (user) => {
      if (user) {

        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log(user)
        setSession(user)
        //const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    })  }, [])

  return (
    <>
    <Routes>
      <Route path="/" element={!session ? <Public session={session}/> : <Play session={session}/>}/>
      <Route path="account" element={!session ? <Public /> : <Account session={session} />}/>
      <Route path="welcome" element={<Welcome session={session} />}/>
      
      <Route path="play" element={<Play />}/>
      <Route path="submit" element={<Submit />}/>
    </Routes>
    </>
  )
}

export default App;