// Import libs
import { useEffect, useState } from 'react'
import Typist from 'react-typist';
import { ParallaxProvider } from 'react-scroll-parallax';

// Import custom components
import Header from './components/Header'
import Footer from './components/Footer'

export default function Public({ session }) {
  return (
    <>
      <div className="overflow-hidden">


        <div className='px-4 sm:px-6 lg:px-8 h-screen flex flex-col'>
          <Header session={!session ? { session } : false} />
          <div className='flex flex-row flex-wrap justify-between h-full bg-white items-center'>
            <div className='basis-full lg:basis-2/3'>
              <h1 className="text-4xl tracking-tight font-display text-black sm:text-4xl md:text-6xl">
                <Typist>
                  <span className="block xl:inline font-display">Fair money distribution between artists</span>
                </Typist>
              </h1>
              <p className="mt-4 font-body text-black sm:mt-5 sm:mx-auto md:mt-5 lg:mx-0">
                Streaming platform that uses blockchain to make subscription payments only to the ones you listen
              </p>
              <div className="mt-5 sm:mt-8 flex sm:justify-center lg:justify-start items-start place-items-start flex-wrap">
                
                  <input className="w-80 mt-3 sm:mt-0 mr-2 placeholder:text-slate-400 placeholder:font-body  bg-white border border-slate-400 rounded-md  focus:border-primary focus:ring-primary focus:ring-1 p-3 m-2" placeholder="Your e-mail" type="text" name="email" />
                
                
                  <a href="#" className="w-80 mt-3 sm:mt-0 mr-2 border border-transparent text-base font-medium rounded-md text-white font-body bg-primary hover:bg-black p-3 m-2"> Notify me when it's ready </a>
                
              </div>
            </div>
            <div className='basis-0 sm:basis-1/3 justify-center items-center shrink-0 invisible lg:visible'>
              <img className="w-64 h-64 m-auto" src="image3.jpg" />
            </div>
          </div>
        </div>


        <div className='mx-0 px-4 sm:px-6 lg:px-8 h-screen bg-gray'>
          <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <p className="mt-2 text-3xl leading-8 text-black sm:text-4xl font-display">
                  FOR ARTISTS
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-between">
            <div className="sm:basis-0 md:basis-1/2 lg:basis-1/2 sm:invisible md:visible lg:visible">
              <img className="ms:shrink-0" src="image1.jpeg" />
            </div>
            <div className="sm:basis-full md:basis-1/2 lg:basis-1/2">
              <ul className='list-disc list-inside font-body text-black'>
                <li>Create your personal wallet to use in the system</li>
                <li>Upload you track</li>
                <li>Add your collaborators if you wish to split revenues</li>
                <li>We will create an NFT out of that</li>
                <li>We will stream your NFT at our app</li>
                <li>You receive a share of each user's subscription who listened to your track</li>
              </ul>
            </div>
          </div>
        </div>

        <section className='mx-0 px-4 sm:px-6 lg:px-8 h-screen'>
          <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <p className="mt-2 text-3xl leading-8 text-black sm:text-4xl font-display">
                  FOR LISTENERS
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-between">
            <div className="sm:basis-0 md:basis-1/2 lg:basis-1/2 sm:invisible md:visible lg:visible">
              <img className="md:shrink-0" src="image2.jpeg" />
            </div>
            <div className="sm:basis-full md:basis-1/2 lg:basis-1/2">
              <ul className='list-disc list-inside font-body text-black'>
                <li>You support the artists you listen</li>
                <li>Full transparency of operations</li>
                <li>Buy NFTs and support new launches</li>
              </ul>
            </div>
          </div>
        </section>

        <section className='mx-0 px-4 sm:px-6 lg:px-8 bg-scroll bg-center bg-cover bg-norepeat h-screen' style={{ backgroundImage: `url('image4.jpg')` }} >
          <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <p className="mt-2 text-3xl leading-8 text-white sm:text-4xl font-display">
                  UNLABEL PROTOCOL
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-between place-items-center">
            <div className="basis-1/2 text-center">
            </div>
            <div className="basis-full">
              <ul className='list-disc list-inside font-body text-white'>
                <li>Protocol is managed by the DAO</li>
                <li>All artists receive governance tokens as they join the DAO</li>
                <li>Artists place their NFT audio to the protocol and receive 80% of subscriptions</li>
                <li>Protocol issues utility tokens which can be bought and traded by anyone</li>
                <li>Owners of utility tokens receive a share of 20% from all subscriptions</li>
              </ul>
            </div>
          </div>
        </section>


      </div>
      <Footer />
    </>
  )
}

/*

set max width in section: max-w-7xl

<main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 mb-10">

<section className='className="mt-10 mx-0 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>

*/